import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";
import SlideCard from "./SlideCard";
import styled from "styled-components";
export default function ListC({
  info
}) {
  return <section className="mGroup5 p-[40px_0_40px] mb-[128px] mobile:p-[20px_0_20px] mobile:mb-[40px]">
      {info.items && <SwiperWrapper speed={1000} modules={[Autoplay, FreeMode]} slidesPerView="auto" freeMode={{
      enabled: true,
      momentumVelocityRatio: 1.3
    }} autoplay={{
      delay: 0,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }} loop={true} breakpoints={{
      360: {
        speed: 1500,
        centeredSlides: true
      },
      1024: {
        speed: 1500,
        centeredSlides: true
      }
    }}>
          {info.items?.map((item, index) => <SwiperSlide key={index}>
              <SlideCard item={item} />
            </SwiperSlide>)}
        </SwiperWrapper>}
    </section>;
}
const SwiperWrapper = styled(Swiper).withConfig({
  displayName: "ListC__SwiperWrapper",
  componentId: "sc-e49zxw-0"
})([".swiper-wrapper{transition-timing-function:linear;.swiper-slide{", "}}"], {
  "marginLeft": "100px",
  "width": "auto",
  "@media (max-width: 767px)": {
    "marginLeft": "24px"
  }
});