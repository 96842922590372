import BulletEm from "../common/BulletEm";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { Contains } from '@modules/utils/Contains';
import { isMobile } from "react-device-detect";
import dayjs from "dayjs";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from '@modules/utils/SearchDataHandler';
import BulletEmCenter from "../common/BulletEmCenter";
import Image from "next/image";
export default function SlideCard({
  item
}) {
  const {
    t
  } = useTranslation("common");
  return <Link href={{
    pathname: "/hotels/[id]",
    query: {
      id: item.hotel_id,
      "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
      "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(getSearchRoomsCookie()),
      query: item.hotel_name,
      searchId: item.hotel_id,
      searchType: Contains.HOTEL
    }
  }} className="block">
      <div className="img relative overflow-hidden rounded-[12px] h-[250px] mobile:h-[214px]">
        <img className="absolute left-0 top-0 w-full h-full object-cover" src={item.thumbnail_url} onError={e => {
        e.target.src = "/images/common/img_default_3by4.png";
      }} alt="" />
      </div>
      <div className="cnt pt-[20px] text-center whitespace-nowrap mobile:pt-[14px] mobile:mr-[8px] mobile:ml-[8px]">
        <span className="block text-Gray-400 SB_14_100_Medium tablet:C_12_100_Medium mobile:C_12_100_Medium">
          {item?.star_rating > 0 ? `${item?.star_rating}${t("search.txt.star")}` : ""}
          {item?.star_rating > 0 && ` · `}
          {item.region_name}
        </span>
        <p className="overflow-ellipsis whitespace-nowrap overflow-hidden pt-[8px] text-Gray-800 H6_18_100_Bold tablet:B_16_100_Bold mobile:B_16_100_Bold">
          {item.hotel_name}
        </p>
        <p className="desc pt-[12px] SB_14_150_Regular mobile:SB_14_150_Regular text-PositiveBlue">
          {item.description}
        </p>
      </div>
    </Link>;
}