import BtnSch from '@public/images/ttbb/bg_btn_sch.svg';
function SearchBtn({
  onClick,
  text = '',
  type = ''
}) {
  return <>
            <button onClick={onClick} className={`absolute flex justify-center items-center
                ${type === 'main_search' && `btnSearch bg-Primary500 right-[8px] top-[8px] w-[120px] h-[56px] rounded-full text-center B_16_100_Bold 
                tablet:w-[48px] tablet:h-[48px] tablet:top-[3px] tablet:right-[4px]
                mobile:w-[40px] mobile:h-[40px] `}
                `}>
               <BtnSch className='w-[40px] h-[40px] hidden tablet:block' />
               <span className='tablet:hidden'>{text}</span>
            </button>

        </>;
}
export default SearchBtn;