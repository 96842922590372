import styles from "../Main.module.css";
import { useRouter } from "next/router";
import { useTablet } from "@modules/hooks/useMedia";
import { getGlobaleurSso } from "@modules/api/Sso";
import { getAccessToken } from "@modules/utils/AccessToken";
export default function BannerB({
  info
}) {
  const router = useRouter();
  const isTablet = useTablet();
  const {
    locale
  } = useRouter();
  const isLoggedIn = typeof window !== undefined && getAccessToken() !== '';
  const linkHandler = () => {
    if (isLoggedIn) {
      getGlobaleurSso(locale).then(data => {
        if (data.body.code == 'SUCCESS') {
          router.push(data.body.item);
        } else {
          router.push(process.env.NEXT_PUBLIC_TTBB_PLAN_URL);
        }
      });
    } else {
      router.push(process.env.NEXT_PUBLIC_TTBB_PLAN_URL);
    }
  };
  let imgUrl = '';
  const infoItems = info && info?.items?.map(item => {
    if (isTablet) {
      imgUrl = item["thumbnail_url"];
    } else {
      imgUrl = item["main_image_url"];
    }
  });
  return <div className={`${styles.mGroup4} mGroup4 pt-[40px] pb-[60px] mb-[128px] mobile:pt-[20px] mobile:pb-[33px] mobile:mb-[40px]`} onClick={() => linkHandler()}>
              <img src={imgUrl} alt={`배너`} className={'w-[1180px] h-[160px] rounded-xl mx-auto tablet:w-full tablet:h-full tablet:rounded-none cursor-pointer'} />
            </div>;
}