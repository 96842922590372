import BulletEm from "../common/BulletEm";
import Link from "next/link";
import { Commify } from "@modules/utils/Commify";
import { useTranslation } from "next-i18next";
import { Contains } from "@modules/utils/Contains";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import { getRoomQuery, getSearchDateCookie, getSearchRoomsCookie } from "@modules/utils/SearchDataHandler";
import Image from "next/image";
export default function SlideCard({
  item
}) {
  const {
    locale
  } = useRouter();
  const {
    t
  } = useTranslation("common");
  return <Link href={{
    pathname: "/hotels/[id]",
    query: {
      id: item.hotel_id,
      "check-in": dayjs(getSearchDateCookie().startDate).format("YYYY.MM.DD"),
      "check-out": dayjs(getSearchDateCookie().endDate).format("YYYY.MM.DD"),
      ...getRoomQuery(getSearchRoomsCookie()),
      query: item.hotel_name,
      searchId: item.hotel_id,
      searchType: Contains.HOTEL
    }
  }} className="block">
      <div className="img relative overflow-hidden rounded-[12px] pb-[73.3%] tablet:h-[205px] mobile:h-[154px] tablet:pb-0">
        <Image fill sizes="768" className="absolute left-0 top-0 w-full h-full object-cover" src={item.thumbnail_url} onError={e => {
        e.target.src = "/images/common/img_default_3by4.png";
      }} alt="" />
      </div>
      <div className="cnt pt-[14px] text-left whitespace-nowrap mobile:mr-[8px]">
        <span className="block text-Gray-400 C_12_100_Medium ">
          {item.star_rating}
          {t("search.txt.star")}
          <BulletEm>{item.region_name}</BulletEm>
        </span>
        <p className="B_16_145_Medium overflow-ellipsis pt-[4px] text-Gray-800 line-clamp-2 whitespace-normal font-bold">
          {item.hotel_name}
        </p>
        <div className="price H4_24_100_Bold pt-[8px] mobile:H5_20_100_Bold">
          {locale === "en-US" && "$"}
          {Commify(item.price)}
          <sub className="inline-block p-0 pl-[4px] align-middle relative top-[1px] text-Gray-900 SB_14_100_Regular mobile:C_12_100_Medium font-bold">
            {locale === "ko-KR" && "원~"}
            {locale === "en-US" && "~"}
          </sub>
        </div>
      </div>
    </Link>;
}