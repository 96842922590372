import Title01 from "../common/Title01";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import Link from "next/link";
import { Navigation, Pagination } from "swiper";
import React from "react";
import styles from "../Main.module.css";
import dynamic from "next/dynamic";
import Image from "next/image";
const MediaQuery = dynamic(() => import("react-responsive"), {
  ssr: false
});
export default function BannerE({
  info
}) {
  return <section className={`${styles.mGroup6} mGroup6 relative pt-[40px] pb-[40px] mb-[128px] overflow-hidden
        tablet:p-[0_24px_40px]
        mobile:m-0 mobile:p-[20px_0_20px] mobile:mb-[40px] mobile:overflow-hidden`}>
      {info.title && <Title01>{info.title}</Title01>}
      {info.items && <>
          <MediaQuery minWidth={768}>
            <SwiperWrapper>
              <Swiper modules={[Navigation, Pagination]} slidesPerView={4} slidesPerGroup={4} spaceBetween={18} pagination={{
            el: ".mGroup6 .swiper-pagination"
          }} navigation={{
            prevEl: ".mGroup6 .swiper-button-prev",
            nextEl: ".mGroup6 .swiper-button-next"
          }} breakpoints={{
            360: {
              spaceBetween: 16
            },
            1024: {
              spaceBetween: 18
            }
          }}>
                {info.items.map((item, index) => <SwiperSlide key={index}>
                    <Link href={{
                pathname: "/city-story",
                query: {
                  region_id: item.region_id,
                  region_type: item.region_type,
                  search_banner_item_id: item.id
                }
              }} className="block rounded-[12px] overflow-hidden pt-[100%] relative
                           before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-[100%]
                           before:bg-[linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(0,0,0,0.3)_64.53%,rgba(0,0,0,0.6)_149.29%)]
                           ">
                      <Image fill sizes="768" src={item?.thumbnail_url} onError={e => {
                  e.target.src = "/images/common/img_default_3by4.png";
                }} className={'absolute left-0 top-0 w-full h-full object-cover'} alt="" />
                      <div className="area text-white absolute left-[24px] bottom-[24px] mobile:left-[16px] mobile:bottom-[16px]">
                        <p className="H4_24_145_Bold tablet:H5_20_145_Bold mobile:B_16_145_Bold">
                          {item?.title}
                        </p>
                        {item?.sub_title && <span className="block pt-[4px] B_16_100_Regular tablet:SB_14_100_Regular mobile:SB_14_100_Regular">
                            {item?.sub_title}
                          </span>}
                      </div>
                    </Link>
                  </SwiperSlide>)}
              </Swiper>
              <div className="swiper-pagination"></div>
              <div className={`${styles.swiperButtonPrev} swiper-button-prev w-[48px] h-[48px] text-black !top-[50%] !left-1/2 ml-[-691px] after:!text-[0]
            bg-ic_arrow_right_ou_b_g90 rotate-180 bg-center bg-no-repeat bg-[length:48px_48px] tablet:!hidden`} />
              <div className={`${styles.swiperButtonNext} swiper-button-next w-[48px] h-[48px] text-black !top-[50%] !right-1/2 mr-[-691px] after:!text-[0]
            bg-ic_arrow_right_ou_b_g90 bg-center bg-no-repeat bg-[length:48px_48px] tablet:!hidden`} />
            </SwiperWrapper>
          </MediaQuery>
          <MediaQuery maxWidth={767}>
            <ListTumb className="mobile:m-0 mobile:whitespace-nowrap mobile:overflow-x-auto mobile:overflow-y-hidden mobile:scrollbar-hide">
              <ul className="flex m-[-22px_auto_0] w-auto mobile:my-0 mobile:mx-[16px] mobile:inline-flex gap-[16px]">
                {info.items?.map((item, index) => <li key={index} className="mobile:w-[180px]">
                    <Link href={{
                pathname: "/city-story",
                query: {
                  region_id: item.region_id,
                  region_type: item.region_type,
                  search_banner_item_id: item.id
                }
              }} className="block rounded-[12px] overflow-hidden max-h-[280px] relative
                           before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-[100%]
                           before:bg-[linear-gradient(180deg,rgba(0,0,0,0)_0%,rgba(0,0,0,0.3)_64.53%,rgba(0,0,0,0.6)_149.29%)]
                           mobile:max-h-[180px]">
                      <img src={item?.thumbnail_url} onError={e => {
                  e.target.src = "/images/common/img_default_3by4.png";
                }} alt="" />
                      <div className="area text-white absolute left-[24px] bottom-[24px] mobile:left-[16px] mobile:bottom-[16px]">
                        <p className="H4_24_145_Bold tablet:H5_20_145_Bold mobile:B_16_145_Bold">
                          {item?.title}
                        </p>
                        {item?.sub_title && <span className="block pt-[4px] B_16_100_Regular tablet:SB_14_100_Regular mobile:SB_14_100_Regular">
                            {item?.sub_title}
                          </span>}
                      </div>
                    </Link>
                  </li>)}
              </ul>
            </ListTumb>
          </MediaQuery>
        </>}
    </section>;
}
const SwiperWrapper = styled.div.withConfig({
  displayName: "BannerE__SwiperWrapper",
  componentId: "sc-c28r4-0"
})([".swiper{", " .swiper-slide{", "}.swiper-slide:last-child{", "}}.swiper-pagination{", " .swiper-pagination-bullet{", "}.swiper-pagination-bullet:first-child{", "}.swiper-pagination-bullet-active{", "}}.swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled{", "}"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "0px",
  "marginBottom": "0px",
  "maxWidth": "1180px",
  "@media (max-width: 1024px)": {
    "maxWidth": "976px",
    "overflow": "hidden"
  },
  "@media (max-width: 767px)": {
    "width": "initial",
    "overflow": "visible"
  }
}, {
  "width": "calc((100% - 64px) / 4)",
  "@media (max-width: 1024px)": {
    "width": "calc((100% - 80px) / 4)"
  },
  "@media (max-width: 767px)": {
    "width": "180px"
  }
}, {
  "@media (max-width: 767px)": {
    "marginRight": "0px"
  }
}, {
  "position": "relative",
  "bottom": "unset",
  "width": "100%",
  "paddingTop": "21px",
  "@media (max-width: 767px)": {
    "display": "none"
  }
}, {
  "margin": "0 0 0 8px",
  "height": "8px",
  "width": "8px",
  "borderRadius": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
  "opacity": "0.25"
}, {
  "marginLeft": "0px"
}, {
  "opacity": "1"
}, {
  "opacity": ".12"
});
const ListTumb = styled.div.withConfig({
  displayName: "BannerE__ListTumb",
  componentId: "sc-c28r4-1"
})(["::-webkit-scrollbar{", "}"], {
  "height": "0"
});