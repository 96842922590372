import {getOs} from "@modules/utils/connectSignBridge";

export function outLinkBanner(item) {
  const os = getOs();
  const common = { callback : 'callBackGoNativeScreen' }
  const configKbpay = JSON.stringify({
    common,
    "menuDsc" : '',
    "menuNm" : '',
    "screenId" : "kbp_life_004",
    "svcSceDtcd" : "1",
    "moveUrl" : "https://kbpay-life.kbcard.com/CMCTRE1",
    "params": ""
  })

  if (os === 'android') {
    window.kbpayBridge?.goNativeScreen(configKbpay);
  } else if (os === 'ios') {
    window.webkit?.messageHandlers?.goNativeScreen.postMessage(configKbpay);
  }
}

const callBackGoNativeScreen = () => {}