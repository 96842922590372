import Link from "next/link";
import { Contains } from "../../../modules/utils/Contains";
import Image from "next/image";
export default function SlideCard({
  item
}) {
  return <Link href={{
    pathname: "/search",
    query: {
      query: item.region_name,
      searchId: item.region_id,
      searchType: Contains.REGION
    }
  }} className="flex items-center">
      <div className="img relative overflow-hidden w-[88px] h-[88px] rounded-[100px] bg-[#CECECE]
                   tablet:w-[80px] tablet:h-[80px]
                   mobile:w-[66px] mobile:h-[66px]">
        {item.thumbnail_url && <Image fill sizes="768" className="absolute left-0 top-0 w-full h-full object-cover" src={item.thumbnail_url} onError={e => {
        e.target.src = "/images/common/img_default_3by4.png";
      }} alt="" />}
      </div>
      <span className="ml-[30px] D6_32_100_Bold text-Gray-900 tablet:H3_26_145_Bold mobile:H5_20_145_Bold mobile:ml-[16px]">
        {item.title}
      </span>
    </Link>;
}