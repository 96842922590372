import {getOs} from "@modules/utils/connectSignBridge";


export function connectReqCheckLifeSession(opt={}, common={ callback: `reqCheckLifeSession` }) {
    const os = getOs();

    if (os === 'android') {
        window.kbpayBridge?.reqCheckLifeSession(
            JSON.stringify({
                opt,common
            })
        );
    } else if (os === 'ios') {
        window.webkit?.messageHandlers?.reqCheckLifeSession.postMessage(
            JSON.stringify({
                opt,common
            })
        );
    }
}