import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styled from "styled-components";
import Link from "next/link";
import styles from "../Main.module.css";
import Image from "next/image";
import { outLinkBanner } from "@modules/utils/connetOutLinkBanner";
import { gtagEvent } from '@utils/gtm';
export default function BannerA({
  info
}) {
  return <section className={`${styles.mGroup3} mGroup3 relative pt-[40px] pb-[40px] mb-[128px] overflow-hidden
          mobile:pt-[20px] mobile:pb-[20px] mobile:mb-[40px] mobile:mx-[0]`}>
      {info.items && <SwiperWrapper>
          <Swiper modules={[Navigation, Pagination]} slidesPerView="2" slidesPerGroup="2" loopFillGroupWithBlank={true} pagination={{
        el: ".mGroup3 .swiper-pagination"
      }} navigation={{
        prevEl: ".mGroup3 .swiper-button-prev",
        nextEl: ".mGroup3 .swiper-button-next"
      }} breakpoints={{
        360: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        767: {
          slidesPerView: 1,
          slidesPerGroup: 1
        },
        1023: {
          slidesPerView: 2,
          slidesPerGroup: 2
        }
      }} onTouchStart={() => {
        gtagEvent("sliderSwipe_main_bannerA", {});
      }}>
            {info.items?.map((item, index) => {
          if (item.title === "KB Pay 해외 항공권 구매 숙박 할인" || item.title === '항공부터 숙박까지 다 되는 KB Pay 라이프 여행!') return <SwiperSlide key={index}>
                  <a onClick={() => outLinkBanner(item)} className="flex rounded-xl overflow-hidden pb-[27.12%] relative cursor-pointer">
                    <Image fill sizes="768" className="w-full" src={item.thumbnail_url} alt=""
              // style={{ inset: "50%" }}
              />
                  </a>
                </SwiperSlide>;
          return <SwiperSlide key={index}>
                  <Link href={item.web_link} className="flex rounded-xl overflow-hidden pb-[27.12%] relative">
                    <Image fill sizes="768" className="w-full" src={item.thumbnail_url} alt=""
              // style={{ inset: "50%" }}
              />
                  </Link>
                </SwiperSlide>;
        })}
          </Swiper>
          <div className="swiper-pagination"></div>
          <div className={`${styles.swiperButtonPrev} swiper-button-prev !w-[48px] !h-[48px] !text-black !top-[118px] !left-1/2 ml-[-691px] after:!text-[0] 
            bg-ic_arrow_right_ou_b_g90 rotate-180 bg-center bg-no-repeat bg-[length:48px_48px] tablet:!hidden`} />
          <div className={`${styles.swiperButtonNext} swiper-button-next !w-[48px] !h-[48px] !text-black !top-[118px] !right-1/2 mr-[-691px] after:!text-[0] 
            bg-ic_arrow_right_ou_b_g90 bg-center bg-no-repeat bg-[length:48px_48px] tablet:!hidden`} />
        </SwiperWrapper>}
    </section>;
}
const SwiperWrapper = styled.div.withConfig({
  displayName: "BannerA__SwiperWrapper",
  componentId: "sc-1l4jwcz-0"
})([".swiper{", " .swiper-slide{", "}.swiper-slide a{", "}.swiper-slide:first-child a{", "}.swiper-slide img{", "}}.swiper-pagination{", " .swiper-pagination-bullet{", "}.swiper-pagination-bullet:first-child{", "}.swiper-pagination-bullet-active{", "}}.swiper-button-next.swiper-button-disabled,.swiper-button-prev.swiper-button-disabled{", "}"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "0px",
  "marginBottom": "0px",
  "width": "auto",
  "maxWidth": "1180px",
  "overflow": "hidden",
  "whiteSpace": "nowrap",
  "@media (max-width: 1024px)": {
    "margin": "0"
  },
  "@media (max-width: 767px)": {
    "margin": "0px",
    "width": "100%"
  }
}, {
  "position": "relative",
  "display": "inline-block",
  "width": "calc(50% - 7px)",
  "overflow": "hidden",
  "@media (max-width: 767px)": {
    "margin": "0px",
    "height": "28.3vw",
    "width": "100%",
    "borderRadius": "0"
  }
}, {
  "marginLeft": "10px",
  "marginRight": "10px",
  "@media (max-width: 1024px)": {
    "margin": "0px",
    "height": "28.3vw",
    "borderRadius": "0"
  },
  "@media (max-width: 767px)": {
    "margin": "0px",
    "height": "28.3vw",
    "width": "100%",
    "borderRadius": "0"
  }
}, {
  "marginLeft": "0px"
}, {
  "position": "absolute",
  "left": "0px",
  "top": "0px",
  "width": "100%",
  "overflow": "hidden",
  "borderRadius": "0.75rem",
  "@media (max-width: 1024px)": {
    "borderRadius": "0"
  },
  "@media (max-width: 767px)": {
    "borderRadius": "0"
  }
}, {
  "position": "relative",
  "bottom": "unset",
  "width": "100%",
  "paddingTop": "21px",
  "@media (max-width: 767px)": {
    "paddingTop": "7px"
  }
}, {
  "margin": "0 0 0 8px",
  "height": "8px",
  "width": "8px",
  "borderRadius": "100%",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(0 0 0 / var(--tw-bg-opacity))",
  "opacity": "0.25",
  "@media (max-width: 767px)": {
    "height": "6px",
    "width": "6px"
  }
}, {
  "marginLeft": "0px"
}, {
  "opacity": "1"
}, {
  "opacity": ".12"
});