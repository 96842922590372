export default function Title01({
  children,
  className,
  style
}) {
  return <h3 className={`title01 relative z-[2] text-center pb-[31px] D6_32_100_Bold text-Gray-900 
                  tablet:block tablet:H3_26_145_Bold tablet:pb-[24px] mobile:H5_20_145_Bold 
                  ${className ?? ""}`} style={style}>
      {children}
    </h3>;
}