import TabBtn from "@components/common/button/TabBtn";

/**
 * 메인페이지 탭 컴포넌트
 * @param tabArr 탭을 그릴 데이터 배열
 * @param selectTab
 * @param callbacks 콜백 함수
 * @returns {JSX.Element}
 * @constructor
 */
export default function MainTab({
  tabArr,
  selectTab,
  callbacks
}) {
  const selectTabCallbacks = index => {
    callbacks(index, "onTabClick");
  };
  return <div className="flex items-center justify-center py-1">
      <div className="overflow-x-scroll whitespace-nowrap scrollbar-hide">
        {tabArr.map((v, i) => {
        return <TabBtn key={i} onClick={() => {
          selectTabCallbacks(i);
        }} isSelected={selectTab === i} text={v.title} className="ml-2 first:ml-4 last:mr-4 mobile:ml-1.5" />;
      })}
      </div>
    </div>;
}